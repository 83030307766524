/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                // var count = 0;
                $('.menu-item-has-children > a').click(function(event) {
                    // count++;
                    $(this).toggleClass('opened');
                    event.preventDefault();
                    $(this).next('.sub-menu').slideToggle(200);
                });
                var headerHeight = $('.header-main').outerHeight();
                // var marNegSearch = parseInt($('.search-field').css('margin-top'));
                var calc = headerHeight - 25;

                $(window).on("scroll", function(e) {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > calc) {
                        $(".header-main").addClass('scrolled-header');
                    } else {
                        $(".header-main").removeClass('scrolled-header');
                    }

                });



                $(".close-sidebar").click(function() {
                    $.sidr('close', 'menu');
                });


                var wrap = $('body');

                wrap.on("scroll", function(e) {

                    if (this.scrollTop > 100) {
                        $(".header-main").addClass(".headhesive");
                    } else {
                        $(".header-main").addClass(".headhesive");
                    }

                });


            },

            finalize: function() {
                $('.main-toggle').sidr({
                    side: 'right',
                    name: 'menu'
                });
                $('#menu').show();


            }
        },
        // Home page
        'home': {
            init: function() {
                $('.owl-carousel').owlCarousel({
                    items: 1,
                    autoplay: false,
                    autoplayTimeout: 5000,
                    autoplayHoverPause: true,
                    dots: true,
                    navRewind: true,
                    loop: true
                });
                $('#basicExample2').justifiedGallery({
                    rowHeight: 260,
                    lastRow: 'nojustify',
                    margins: 3,
                    captions: true
                }).on('jg.complete', function(e) {
                    $('.justified-gallery').Chocolat();
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'find_a_dealer': {
            init: function() {
                $("#refForm").validate({

                    rules: {
                        'ref_name': {
                            required: true,
                            minlength: 2
                        },
                        'ref_zipcode': {
                            required: true,
                            number: true,
                            minlength: 5,
                            maxlength: 5
                        },
                        'ref_phone': {
                            required: true
                        },
                        'ref_email': {
                            required: true,
                            email: true
                        },
                        'ref_from': {
                            required: true
                        },
                        'ref_materials_desired[]': {
                            required: true,
                            minlength: 1
                        },
                        'ref_project_type': {
                            required: true
                        },
                        'ref_estimated_budget': {
                            required: true
                        },
                        'ref_estimated_completion': {
                            required: true
                        }
                    },
                    messages: {
                        'ref_name': {
                            required: "Please enter a name",
                            minlength: "Your name must consist of at least 2 characters"
                        }
                    },


                    errorPlacement: function(error, element) {
                        error.addClass("ui red pointing label transition");
                        error.insertAfter(element.parent());
                    },
                    highlight: function(element, errorClass, validClass) {
                        $(element).parents(".field").addClass(errorClass);
                    },
                    unhighlight: function(element, errorClass, validClass) {
                        $(element).parents(".field").removeClass(errorClass);
                    },
                    submitHandler: function(form) {
                        // do other things for a valid form
                        // form.submit();
                        var options = {

                            success: showResponse

                        };

                        function showResponse(responseText, statusText, xhr, $form) {
                            $("#modal-alert").iziModal({

                                title: responseText,
                                icon: 'icon-check',
                                headerColor: '#00af66',
                                width: 600,
                                timeout: 10000,
                                timeoutProgressbar: true,
                                transitionIn: 'fadeInUp',
                                transitionOut: 'fadeOutDown',
                                attached: 'bottom',
                                pauseOnHover: true

                            });

                            $('#modal-alert').iziModal('open');
                            $("#refForm").resetForm();
                        }

                        $(form).ajaxSubmit(options);
                    }

                });

                $('#ref_project_type').change(function() {


                    if ($(this).val() === 'kitchen_both' | $(this).val() === 'bathroom_demo' | $(this).val() === 'new_con') {
                        $('.field-cabinets').show();
                        $('.field-project-other').hide();
                        $("#ref_cabinet_purchase").rules("add", {
                            required: true
                        });
                        $("#ref_project_other").rules('remove');
                    } else if ($(this).val() === 'other') {
                        $('.field-project-other').show();
                        $('.field-cabinets').hide();
                        $("#ref_cabinet_purchase").rules('remove');
                        $("#ref_project_other").rules('add', 'required');
                    } else {
                        $('.field-cabinets').hide();
                        $('.field-project-other').hide();
                        $("#cabinets").rules('remove');
                        $("#project-other").rules('remove');
                    }

                });
                $('#ref_from').change(function() {


                    if ($(this).val() === 'other') {
                        $('.field-ref-other').show();
                    } else {
                        $('.field-ref-other').hide();
                    }
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },

        'cambria': {
            init: function() {
                $('#cambria_gallery').justifiedGallery({
                    rowHeight: 260,
                    lastRow: 'nojustify',
                    margins: 3,
                    captions: false
                }).on('jg.complete', function(e) {
                    $('.justified-gallery').Chocolat();
                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'inspiration_gallery': {
            init: function() {
                $('#inspiration-gallery').justifiedGallery({
                    rowHeight: 260,
                    lastRow: 'nojustify',
                    margins: 3,
                    captions: true
                }).on('jg.complete', function(e) {
                    $('.justified-gallery').Chocolat();
                });
                var filters_arr = [];
                $('.filter').click(function() {

                    $(this).toggleClass('active');
                    filter = $(this).data('filter');
                    if (filter == "all") {
                        $("#inspiration-gallery").justifiedGallery({
                            filter: false
                        }).on('jg.complete', function(e) {
                            $('.justified-gallery').Chocolat();
                        });
                        $(this).siblings().removeClass('active');
                        filters_arr = [];
                    } else {
                        var index = filters_arr.indexOf(filter);
                        if (index > -1) {
                            filters_arr.splice(index, 1);
                        } else {
                            filters_arr.push(filter);
                        }
                        if (filters_arr.length > 0) {
                            $("#inspiration-gallery").justifiedGallery({
                                filter: filters_arr.join()
                            }).on('jg.complete', function(e) {
                                $('.justified-gallery').Chocolat();
                            });
                            $(".show-all").removeClass('active');
                        } else {
                            $("#inspiration-gallery").justifiedGallery({
                                filter: false
                            }).on('jg.complete', function(e) {
                                $('.justified-gallery').Chocolat();
                            });
                            $(".show-all").addClass('active');
                        }

                    }




                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'page_template_template_look_interior_php': {
            init: function() {
                $('#int_gallery').justifiedGallery({
                    rowHeight: 260,
                    lastRow: 'nojustify',
                    margins: 3,
                    captions: false
                }).on('jg.complete', function(e) {
                    $('.justified-gallery').Chocolat();
                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'page_template_template_product_inner_php': {
            init: function() {



                $(".filter-cats").change(function() {
                    var swapper = '.' + $(this).val() + '-control';
                    $(".controls").not(swapper).hide();
                    $(swapper).show();

                });


                $('.add-product').click(function(event) {


                    event.preventDefault();

                    var product_name = '<strong>' + $(this).data('product-name') + '</strong>';

                    var post_id = $(this).data('post-id');

                    var product_cat = $(this).data('product-cat');

                    var board_id = $(this).data('board-id');

                    $.ajax({
                        method: "POST",
                        url: "/wp-content/themes/custom-marble-inc/create-design-board.php",
                        data: { post_id: post_id, product_cat: product_cat, board_id: board_id },
                        success: function(data, textStatus, jQxhr) {
                            if(data == 0) {
                                $("#modal-alert").iziModal({

                                    title: product_name + ' was successfully added to your Design Board.',
                                    icon: 'icon-check',
                                    headerColor: '#00af66',
                                    width: 600,
                                    timeout: 10000,
                                    timeoutProgressbar: true,
                                    transitionIn: 'fadeInUp',
                                    transitionOut: 'fadeOutDown',
                                    attached: 'bottom',
                                    pauseOnHover: true

                                });
                            }

                            if(data == 1) {
                                $("#modal-alert").iziModal({

                                    title: product_name + ' is already on your Design Board.',
                                    icon: 'icon-check',
                                    headerColor: '#c74056',
                                    width: 600,
                                    timeout: 10000,
                                    timeoutProgressbar: true,
                                    transitionIn: 'fadeInUp',
                                    transitionOut: 'fadeOutDown',
                                    attached: 'bottom',
                                    pauseOnHover: true

                                });
                            }

                            //$("#modal-alert").iziModal({
                            //
                            //    title: product_name + data,
                            //    icon: 'icon-check',
                            //    headerColor: '#00af66',
                            //    width: 600,
                            //    timeout: 10000,
                            //    timeoutProgressbar: true,
                            //    transitionIn: 'fadeInUp',
                            //    transitionOut: 'fadeOutDown',
                            //    attached: 'bottom',
                            //    pauseOnHover: true
                            //
                            //});

                            $('#modal-alert').iziModal('open');
                            $(document).on('closed', '#modal-alert', function (e) {
                                $('#modal-alert').replaceWith('<div id="modal-alert"></div>');
                            });

                        }
                    });







                });



            },
            finalize: function() {

                $('.products').Chocolat({ linkImages: false });

                var containerEl = document.querySelector('.products');
                var filterError = document.getElementById("filter-error");
                var loading = document.getElementById("loading");
                filterError.style.display = "none";
                var mixer = mixitup(containerEl, {
                    controls: {
                        toggleLogic: 'and'
                    },
                    callbacks: {
                        onMixStart: function() {
                            filterError.style.display = "none";
                            loading.style.display = "block";
                        },
                        onMixEnd: function() {
                            $('.products').Chocolat({ linkImages: false });
                            loading.style.display = "none";
                        },
                        onMixFail: function() {
                            filterError.style.display = "block";
                        }
                    }
                });



                var selectron = $('.filter-selector').selectize({
                    create: true,
                    persist: false,
                    sortField: {
                        field: 'text',
                        direction: 'asc'
                    },
                    onChange: function(value) {
                        var filter_string = [];
                        $(".filter-selector").each(function(index, element) {
                            var value = $(this).val();
                            if (value) {
                                filter_string.push($(this).val());
                            }

                        });
                        var join = filter_string.join('');
                        if (join) {
                            mixer.filter(join);
                        } else {
                            mixer.filter('all');
                        }
                        

                    },
                    dropdownParent: 'body'
                });
                $('.reset-filters').click(function() {
                    mixer.filter('all');
                        for (i = 1; i <= jQuery('.selectize-control').length; i++) {
                            var control = selectron[i-1].selectize;
                            control.clear();
                        }
                });
                selectron.open;
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'design_board': {
            init: function() {



                $('.remove-product').click(function(event) {

                    event.preventDefault();

                    var product_name = '<strong>' + $(this).data('product-name') + '</strong>';

                    var post_id = $(this).data('post-id');

                    //var product_cat = $(this).data('product-cat');

                    var board_id = $(this).data('board-id');

                    var row_id = $(this).data('row-id');

                    remove = $(this).parents('.product-card');

                    $.ajax({
                        method: "POST",
                        url: "/wp-content/themes/custom-marble-inc/remove-design-board.php",
                        data: { post_id: post_id, row_id: row_id, board_id: board_id },
                        success: function(data, textStatus, jQxhr) {
                            $('#modal-alert-2').iziModal({
                                title: product_name + data,
                                icon: 'icon-check',
                                headerColor: '#00af66',
                                width: 600,
                                timeout: 10000,
                                timeoutProgressbar: true,
                                transitionIn: 'fadeInUp',
                                transitionOut: 'fadeOutDown',
                                attached: 'bottom',
                                pauseOnHover: true

                            });

                            $('#modal-alert-2').iziModal('open');
                            $(document).on('closed', '#modal-alert-2', function (e) {
                                $('#modal-alert-2').replaceWith('<div id="modal-alert-2"></div>');
                            });

                            $(remove).fadeOut(200);
                        }
                    });

                });



            },
            finalize: function() {
                                $('.products').Chocolat({ linkImages: false });
                var containerEl = document.querySelector('.products');
                var mixer = mixitup(containerEl, {
                    controls: {
                        toggleLogic: 'and',
                    },
                    callbacks: {
                        onMixEnd: function(state) {
                            $('.products').Chocolat({ linkImages: false });
                        }
                    }
                });



                $('.filter-selector').selectize({
                    create: true,
                    onChange: function(value) {
                        var filter_string = [];
                        $(".filter-selector").each(function(index, element) {
                            var value = $(this).val();
                            if (value) {
                                filter_string.push($(this).val());
                            }

                        });
                        var join = filter_string.join('');
                        if (join) {
                            mixer.filter(join);
                        } else {
                            mixer.filter('all');
                        }
                        

                    },
                    dropdownParent: 'body'
                });
              
            }
        },
        // About us page, note the change from about-us to about_us.
        'surface_collection': {
            init: function() {
                // To keep our code clean and modular, all custom functionality will be contained inside a single object literal called "dropdownFilter".

                var dropdownFilter = {

                    // Declare any variables we will need as properties of the object

                    $filters: null,
                    $reset: null,
                    groups: [],
                    outputArray: [],
                    outputString: '',

                    // The "init" method will run on document ready and cache any jQuery objects we will need.

                    init: function() {
                        var self = this; // As a best practice, in each method we will asign "this" to the variable "self" so that it remains scope-agnostic. We will use it to refer to the parent "dropdownFilter" object so that we can share methods and properties between all parts of the object.

                        self.$filters = $('#Filters');
                        self.$reset = $('#Reset');
                        self.$container = $('.surfaces');

                        self.$filters.find('fieldset').each(function() {
                            self.groups.push({
                                $dropdown: $(this).find('select'),
                                active: ''
                            });
                        });

                        self.bindHandlers();
                    },

                    // The "bindHandlers" method will listen for whenever a select is changed.

                    bindHandlers: function() {
                        var self = this;

                        // Handle select change

                        self.$filters.on('change', 'select', function(e) {
                            e.preventDefault();

                            self.parseFilters();
                        });

                        // Handle reset click

                        self.$reset.on('click', function(e) {
                            e.preventDefault();

                            self.$filters.find('select').val('');

                            self.parseFilters();
                        });
                    },

                    // The parseFilters method pulls the value of each active select option

                    parseFilters: function() {
                        var self = this;

                        // loop through each filter group and grap the value from each one.

                        for (var i = 0, group; group = self.groups[i]; i++) {
                            group.active = group.$dropdown.val();
                        }

                        self.concatenate();
                    },

                    // The "concatenate" method will crawl through each group, concatenating filters as desired:

                    concatenate: function() {
                        var self = this;

                        self.outputString = ''; // Reset output string

                        for (var i = 0, group; group = self.groups[i]; i++) {
                            self.outputString += group.active;
                        }

                        // If the output string is empty, show all rather than none:

                        !self.outputString.length && (self.outputString = 'all');

                        // ^ we can check the console here to take a look at the filter string that is produced

                        // Send the output string to MixItUp via the 'filter' method:

                        if (self.$container.mixItUp('isLoaded')) {
                            self.$container.mixItUp('filter', self.outputString);
                        }
                    }
                };

                // On document ready, initialise our code.

                $(function() {

                    // Initialize dropdownFilter code

                    dropdownFilter.init();

                    // Instantiate MixItUp

                    $('.surfaces').mixItUp({
                        controls: {
                            enable: false // we won't be needing these
                        },
                        callbacks: {
                            onMixFail: function() {
                                alert('No items were found matching the selected filters.');
                            }
                        }
                    });
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
